import request from '@/services/request'

/**
 * 处理创建信息
 * 2021-07-31 14:11:13
 * @author SnowRock
 */
export function createServiceCostOrder(params) {
  return request({
    url: '/mall/makeOrder',
    method: 'post',
    data: params
  })
}
