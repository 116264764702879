<template>
  <div class="app-container">
    <van-form size="small" @submit="onSubmit">
      <van-field
        readonly
        clickable
        name="picker"
        :value="temp.userType"
        label="身份信息"
        placeholder="请选择身份证信息"
        right-icon="arrow"
        :rules="[{ required: true, message: '请选择身份证信息' }]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="value"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="temp.name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="temp.idCard"
        name="证件号码"
        label="证件号码"
        placeholder="请填写证件号码"
        :maxlength=" temp.userType === columns[0] ? 18 : null "
        :rules="[{ required: true, message: `请填写证件号码`}, { validator: validatorIdCard, message: `身份证号必须为18位` }]"
      />
      <van-field
        v-model="temp.phone"
        name="手机号"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <div style="margin: 16px;">
        <van-button round block color="#FF0742" type="info" native-type="submit">下一步</van-button>
      </div>
      <div class="form-tips">
        注意：所填信息及实际支付人均默认为本次确权人。
        共计支付1000元，请确保信息正确
      </div>
    </van-form>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="wrapper-content" @click.stop>
          <div class="close" @click="show = false">
            <van-icon name="close" />
          </div>
          <van-cell-group inset>
            <van-cell title="身份信息" :value="temp.userType" />
            <van-cell title="姓名" :value="temp.name" />
            <van-cell title="证件号码" :value="temp.idCard" />
            <van-cell title="手机号" :value="temp.phone" />
            <van-cell>
              <div class="form-tips">
                注意：所填信息及实际支付人均默认为本次确权人。
                共计支付1000元，请确保信息正确
              </div>
            </van-cell>
          </van-cell-group>
          <div style="margin: 10px 16px;">
            <van-button round block color="#FF0742" type="info" @click="handleCrateOrder">确定信息并支付</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Form as VanForm, Icon as VanIcon,
  Cell as VanCell, CellGroup as VanCellGroup,
  Field as VanField, Button as VanButton, Popup as VanPopup, Picker as VanPicker, Overlay as VanOverlay, Toast
} from 'vant'
import { createServiceCostOrder } from '@/services/activity/service-cost'
import { getPayModule, getPayParams } from '@/services/pay'
import { weChatPay } from '@/utils/payment'
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg } from '@/utils/common/utils'
export default {
  name: 'YtServiceCost',
  components: {
    VanForm, VanField, VanButton, VanPopup, VanPicker, VanOverlay,
    VanIcon,
    VanCell, VanCellGroup
  },
  data() {
    return {
      temp: {
        userType: '中国身份证',
        idCard: '',
        name: '',
        phone: ''
      },
      columns: [
        '中国身份证',
        '港澳通行证或护照'
      ],
      showPicker: false,
      show: false,
      tradeType: 'WX_JSWEB',
      orderNo: null,
      modules: []
    }
  },
  mounted() {
    this.handleSetShare()
  },
  methods: {
    handleSetShare() {
      wxConfigInit({
        title: '账户费用', // 分享标题
        desc: '请确保提供信息真实有效', // 分享描述
        link: changeURLArg(window.location.href, 'codeNumber', localStorage.getItem('recode')), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-17/13/yuelvhuiQJwXComJ821581918103.jpg' // 分享图标
      })
    },
    onSubmit() {
      this.show = true
    },
    /**
     * 获取支付模块信息
     * @returns {Promise<void>}
     */
    async getPayModules() {
      const { orderNo, type = 147 } = this
      const { code, data } = await getPayModule({ orderNo, type, moduleId: 8 })
      if (code === 200) {
        this.modules = data.modules
      }
    },
    handleCrateOrder() {
      const { temp, columns } = this
      const { userType, idCard, name, phone } = temp
      const params = {
        id_card_type: columns.findIndex(i => i === userType) + 1,
        id_card: idCard,
        name, mobile: phone,
        mid: localStorage.getItem('uid')
      }
      createServiceCostOrder(params).then(async res => {
        const { code, data } = res
        const { ordersn: orderNo } = data
        if (code === 200) {
          this.orderNo = orderNo
          await this.getPayModules() // 获取支付模块信息
          const { mchId, tradeType, payChannel, mchAppId } = this.modules.filter((item) => item.tradeType === this.tradeType)[0]
          const params = {
            orderNo, // 主单号
            mchId,
            payChannel,
            tradeType,
            appId: mchAppId,
            moduleId: 8, // 服务费
            openId: localStorage.getItem('openid'),
            returnUrl: 'http://web.yuetao.vip/yt-service-cost-success'
          }
          const { code: payCode, data: payData } = await getPayParams(params)
          if (payCode === 200) {
            const { getwayBody } = payData
            weChatPay(getwayBody).then(res => {
              // 支付成功后的操作 TRADE_SUCCESS
              Toast('支付成功')
              this.$router.push({
                path: '/yt-service-cost-success'
              })
            }).catch(e => {
              if (e) {
                const { msg = null } = e
                if (msg === 'isNotWeChat') {
                  Toast('请在微信浏览器下进行支付')
                }
                if (msg === 'cancel') {
                  // 取消支付的操作 UNKNOWN
                  Toast('取消支付')
                }
                if (msg === 'fail') {
                  // 支付失败的操作 UNKNOWN
                  Toast('支付失败')
                }
              }
            })
          }
        }
      })
    },
    onConfirm(value) {
      this.temp.userType = value
      this.showPicker = false
    },
    validatorIdCard(val) {
      const { columns, temp } = this
      return !(temp.userType === columns[0] && val.length < 18)
    }
  }
}
</script>

<style lang="less" scoped>
.app-container {
  text-align: left;
}
.form-tips {
  color: #FF0742;
  margin: 20px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .close {
    position: absolute;
    top: -30px;
    right: 8px;
    color: #ffffff;
    font-size: 26px;
  }
  ::v-deep .van-cell-group {
    border-radius: 8px;
    overflow: hidden;
  }
  .wrapper-content {
    margin: 20px;
    position: relative;
  }
  .form-tips {
    margin: 10px;
    font-size: 12px;
    line-height: 1.2;
  }
}

</style>
